<template>
    <div>
        <van-empty v-if="$route.query.sensorNumber == 'FFFFFFFFFFFFFFFF'" image="error" description="传感器未发现" />
        <van-empty v-else-if="$route.query.sensorNumber == '0000000000000000'" image="network" description="传感器故障" />
        <div v-else>
            <van-cell title="测点名称" :value="sensorModel.sensorName" :label="$route.query.sensorNumber" is-link @click="dialogUpdateSensorNameVisible = true"></van-cell>
            <van-cell title="最新温度" :value="sensorDataList.length > 0 ? (sensorDataList[0].temperature.toString() + '℃') : ''" value-class="primaryFont" :label="(sensorDataList.length > 0 ? sensorDataList[0].dataTime : '')"></van-cell>
            <van-cell-group title="查询条件">
                <van-field input-align="center" label="开始时间" readonly v-model="beginDate" @click="selectBeginDate" is-link></van-field>
                <van-field input-align="center" label="结束时间" readonly v-model="endDate" @click="selectEndDate" is-link></van-field>
                <van-button block type="info" icon="search" @click="search">查询</van-button>
            </van-cell-group>
            <div id="Wap_WXCW81Chart" style="margin-top: 5px;"></div>
        </div>
        <van-popup v-model="popupBeginDateVisible" position="bottom">
            <van-datetime-picker type="date" v-model="popupBeginDate" title="选择年月日" @cancel="popupBeginDateVisible = false" @confirm="confirmBeginDate"></van-datetime-picker>
        </van-popup>
        <van-popup v-model="popupEndDateVisible" position="bottom">
            <van-datetime-picker type="date" v-model="popupEndDate" title="选择年月日" @cancel="popupEndDateVisible = false" @confirm="confirmEndDate"></van-datetime-picker>
        </van-popup>
        <van-dialog v-model="dialogUpdateSensorNameVisible" title="编辑测点名称" :before-close="beforeClose">
            <van-field label="测点名称" placeholder="请输入测点名称" v-model="sensorModel.sensorName" :error="sensorNameError">
            </van-field>
        </van-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import Moment from "moment";
import HighCharts from "highcharts";
import HighchartsHanHua from "@/util/highcharts-lang-zh_CN.js";
import dark from "highcharts/themes/dark-unica";

dark(HighCharts);
HighchartsHanHua.HighchartsHanHua(HighCharts);
HighCharts.setOptions({ global: { useUTC: false } });

Vue.use(Vant);
export default {
    data() {
        return {
            dialogUpdateSensorNameVisible: false,
            sensorModel: { sensorName: "" },
            sensorDataList: [],
            popupBeginDateVisible: false,
            popupBeginDate: new Date(),
            beginDate: new Moment().format("YYYY-MM-DD"),
            popupEndDateVisible: false,
            popupEndDate: new Date(),
            endDate: new Moment().format("YYYY-MM-DD"),

        };
    },
    computed: {
        sensorNameError() {
            return !this.sensorModel.sensorName;
        }
    },
    methods: {
        selectBeginDate() {
            let that = this;
            that.popupBeginDate = new Moment(that.beginDate).toDate();
            that.popupBeginDateVisible = true;
        },
        confirmBeginDate(v) {
            let that = this;
            that.beginDate = new Moment(v).format("YYYY-MM-DD");
            that.popupBeginDateVisible = false;
        },
        selectEndDate() {
            let that = this;
            that.popupEndDate = new Moment(that.endDate).toDate();
            that.popupEndDateVisible = true;
        },
        confirmEndDate(v) {
            let that = this;
            that.endDate = new Moment(v).format("YYYY-MM-DD");
            that.popupEndDateVisible = false;
        },
        search() {
            let that = this;
            if (Moment(that.endDate) - Moment(that.beginDate) > 3600000 * 24 * 90) {
                that.$toast("时间大于90天");
                return;
            }
            const tmpLoading = that.$loading();
            that.axios
                .post("WXCW81/GetOneSensorData", {
                    sensorId: that.sensorModel.id,
                    beginDate: that.beginDate,
                    endDate: Moment(that.endDate).add(3600000 * 24 - 1, "ms"),
                })
                .then(function (response) {
                    that.sensorDataList = response.data.data;
                    tmpLoading.close();
                    that.loadChart();
                });
        },
        loadChart() {
            let that = this;
            var mapData = [];
            mapData.push({ name: that.sensorModel.sensorName, data: [] });
            for (let i = that.sensorDataList.length - 1; i >= 0; i--) {
                mapData[0].data.push([Moment(that.sensorDataList[i].dataTime).valueOf(), that.sensorDataList[i].temperature]);
            }
            HighCharts.chart("Wap_WXCW81Chart", {
                tooltip: {
                    formatter: function () {
                        return this.series.name + "<br />温度：" + this.y + "℃<br />时间：" + Moment(this.x).format("YYYY-MM-DD HH:mm:ss");
                    },
                },
                title: { text: "温度曲线图" },
                credits: { enabled: false },
                exporting: { enabled: false },
                //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
                yAxis: { title: { text: "温度" } },
                xAxis: { type: "datetime" },
                series: mapData,
            });
        },
        beforeClose(action, done) {
            let that = this;
            if (!that.sensorModel.sensorName) {
                done(false);
            }
            else {
                that.axios.post("WXCW_WXCWSensor/EditModel", that.sensorModel).then((response) => {
                    if (response.data.code == 101) {
                        that.$notify({ type: "success", message: "保存成功" });
                        done();
                    }
                }).catch(() => {
                    that.$notify({ type: "warning", message: "保存失败" });
                    done(false);
                });
            }
        },
        loadSensor() {
            let that = this;
            that.axios.post("WXCW_WXCWSensor/GetModelBySensorNumber", {
                onlyText: that.$route.query.sensorNumber
            }).then((response) => {
                that.sensorModel = response.data.data;
                that.search();
                if (!that.sensorModel.sensorName && that.sensorModel.sensorNumber != 'FFFFFFFFFFFFFFFF' && that.sensorModel.sensorNumber != '0000000000000000') {
                    that.dialogUpdateSensorNameVisible = true;
                }

            });
        }
    },
    mounted() {
        document.title = "测点温度";
        let that = this;
        that.loadSensor();
    }
};
</script>

<style scoped>
.primaryFont {
    color: #67C23A;
    font-weight: bolder;
}
</style>